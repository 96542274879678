import { Help, ProductsList } from '@components/ecommerce';
import { Layout } from '@components/layout';
import { PageHeader } from '@components/shared';
import { ICategory, ILocation, IPage, IProduct } from '@standardTypes';
import React from 'react';

interface CategoryProps {
  pageContext: {
    page: IPage;
    categories: ICategory[];
    products: IProduct[];
  };
  location: ILocation;
}

const descPlaceholder = `Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc at
venenatis egestas amet. Vitae aliquam blandit arcu, amet urna,
tincidunt. Pellentesque velit eget diam adipiscing neque, elit arcu.`;

const CategoryPage: React.FC<CategoryProps> = ({
  pageContext,
  location
}): JSX.Element => {
  const { page, categories, products } = pageContext;
  const { pathname, search } = location;
  const category = categories.filter(({ slug }) => pathname.includes(slug))[0];

  return (
    <Layout {...page}>
      <PageHeader title={category.name} description={descPlaceholder} />
      <ProductsList products={products} pageParam={search} />
      <Help />
    </Layout>
  );
};

export default CategoryPage;
